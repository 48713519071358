<template>
  <section>
    <ApolloQuery
      :query="require('@/graphql/Customers.gql')"
      fetchPolicy="cache-and-network"
    >
      <!-- Loading -->

      <template slot-scope="{ result: { loading, error, data } }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>

        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occured</div>
        <div v-else-if="data">
          <v-card-title>
            Clients
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="filter-input"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="data.customers"
            :search="search"
            class="elevation-1"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
            :items-per-page="30"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
                <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      >Add Client</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field
                                v-model="editedItem.name"
                                hide-details="auto"
                                :rules="requiredRules"
                                label="Legal Name"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-checkbox
                                v-model="editedItem.showPrice"
                                label="Show Prices"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-form>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="save(data)"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">{{ item.name }}</template>
            <!-- <template v-slot:item.admin="{ item }">
            {{
              item.admin
                ? item.admin.firstName +
                  item.admin.lastName +
                  "(" +
                  item.admin.email +
                  ")"
                : "-"
            }}
            </template>-->
            <template v-slot:item.managers="{ item }">
              {{
                !item.managers
                  ? "-"
                  : item.managers
                      .map((item) => item.firstName + " " + item.lastName)
                      .join(", ")
              }}
            </template>
            <template v-slot:item.patients="{ item }">
              {{ item.patients ? item.patients.length : 0 }}
            </template>
            <template v-slot:item.destinations="{ item }">
              {{ item.destinations ? item.destinations.length : 0 }}
            </template>
            <template v-slot:item.orders="{ item }">
              {{ item.orders ? item.orders.length : 0 }}
            </template>

            <template v-slot:item.showPrice="{ item }">
              {{ item.showPrice ? "yes" : "no" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item, data)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Edit Client</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    @click="deleteItem(item, data)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Delete Client</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>

            <!-- expanded -->

            <template v-slot:expanded-item="{ item, headers }">
              <td :colspan="headers.length">
                <div class="d-flex justify-end mr-3 mt-2">
                  <div style="width: 200px;">
                    <v-dialog
                      ref="dialogStart"
                      v-model="invoiceModalVisibleStart"
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="invoiceDateStart"
                          label="Date From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          style="width: 150px"
                          class="ml-2 mr-2"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="invoiceDateStart"
                        :max="maxDateInvoice"
                        @click:date="invoiceModalVisibleStart = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="invoiceModalVisibleStart = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogStart.save(invoiceDateStart)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <div style="width: 200px;">
                    <v-dialog
                      ref="dialogEnd"
                      v-model="invoiceModalVisibleEnd"
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="invoiceDateEnd"
                          label="Date To"
                          prepend-icon="mdi-calendar"
                          readonly
                          style="width: 150px;"
                          class="ml-2 mr-2"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="invoiceDateEnd"
                        :max="maxDateInvoice"
                        @click:date="invoiceModalVisibleEnd = false"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="invoiceModalVisibleEnd = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogEnd.save(invoiceDateEnd)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </div>
                  <v-btn
                    color="primary"
                    dark
                    class="mt-2"
                    :disabled="!invoiceDateStart || !invoiceDateEnd"
                    @click="createInvoice(item)"
                    >Create Invoice</v-btn
                  >
                </div>

                <v-simple-table
                  class="order-expanded-table"
                  style="margin: 20px 0;"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Date Created</th>
                        <th>Date Range</th>
                        <th>Amount</th>
                        <th>Shared</th>
                        <th>Paid</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(invoiceItem,
                        orderItemIdx) in item.invoices.slice().reverse()"
                        :key="orderItemIdx"
                      >
                        <td>
                          <span
                            v-luxon:date_med="{
                              value: invoiceItem.dateCreated + 'T12:00:00',
                            }"
                          />
                        </td>
                        <td>
                          <span
                            v-luxon:date_med="{
                              value: invoiceItem.dateStart + 'T12:00:00',
                            }"
                          />
                          -
                          <span
                            v-luxon:date_med="{
                              value: invoiceItem.dateEnd + 'T12:00:00',
                            }"
                          />
                        </td>
                        <td>${{ invoiceItem.cost }}</td>
                        <td>
                          {{
                            (invoiceItem.shared
                            ? invoiceItem.shared
                            : invoiceItem.shared)
                              ? "yes"
                              : "no"
                          }}
                        </td>
                        <td
                          :style="{ color: invoiceItem.paid ? 'green' : 'red' }"
                        >
                          {{
                            (invoiceItem.paid
                            ? invoiceItem.paid
                            : invoiceItem.paid)
                              ? "yes"
                              : "no"
                          }}
                        </td>
                        <td>
                          <v-tooltip bottom v-if="!invoiceItem.shared">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="shareInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-share</v-icon
                              >
                            </template>
                            <span>Share Invoice</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="downloadInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-download</v-icon
                              >
                            </template>
                            <span>Download Invoice</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                @click="sendInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-email</v-icon
                              >
                            </template>
                            <span>Send Invoice</span>
                          </v-tooltip>

                          <v-tooltip bottom v-if="invoiceItem.shared">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="!invoiceItem.paid"
                                @click="markPaidInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-check</v-icon
                              >
                            </template>
                            <span>Mark Invoice as Paid</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-if="!invoiceItem.paid"
                                @click="deleteInvoice(item, invoiceItem)"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-close</v-icon
                              >
                            </template>
                            <span>Delete Invoice</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <!--  -->
          </v-data-table>
        </div>

        <!-- No result -->
        <div v-else class="no-result apollo">No results</div>
      </template>
    </ApolloQuery>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  data: () => ({
    //
    invoiceDateStart: null,
    invoiceDateEnd: null,
    invoiceModalVisibleStart: false,
    invoiceModalVisibleEnd: false,
    //
    expanded: [],
    singleExpand: false,
    //
    dialog: false,
    search: "",
    headers: [
      {
        text: "Legal Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      // {
      //   text: "Admin",
      //   sortable: false,
      //   value: "admin",
      // },
      { text: "Managers", value: "managers", sortable: true },
      { text: "Passengers", value: "patients", sortable: true },
      { text: "Client Destinations", value: "destinations", sortable: true },
      { text: "Orders", value: "orders", sortable: true },
      { text: "Show Price", value: "showPrice" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      showPrice: false,
    },
    //
    valid: true,
    //  hide-details="auto"
    // :rules="requiredRules"
    requiredRules: [(v) => !!v || "Field is required"],
  }),

  computed: {
    maxDateInvoice() {
      var date = new Date();
      date.setDate(date.getDate() - 1);
      return date.toISOString().substring(0, 10);
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Client" : "Edit Client";
    },
    ...mapGetters(["user", "strapi"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    timeStringToDateTime(timeString) {
      var cFormat = "{y}-{m}-{d}";
      var vals = timeString.split("-");
      var formatSplit = cFormat.split("-");
      var m = formatSplit.indexOf("{m}");
      var d = formatSplit.indexOf("{d}");
      var y = formatSplit.indexOf("{y}");
      // console.log("month", vals[m]);
      // console.log("day", vals[d]);
      // console.log("year", vals[y]);

      return DateTime.local(
        parseInt(vals[y]),
        parseInt(vals[m]),
        parseInt(vals[d]),
        0,
        0,
        0
      );
    },
    initialize() {
      // Object.assign(this.editedItem, this.defaultItem);
      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    },

    editItem(item, data) {
      this.editedIndex = data.customers.findIndex((i) => i.id == item.id);
      // Object.assign(this.editedItem, item);
      this.editedItem = this.lodash.cloneDeep(item);
      // this.editedItem.role = parseInt(item.role.id);
      this.dialog = true;
    },

    async markPaidInvoice(item, invoiceItem) {
      var result = confirm("Are you sure you want to mark invoice as paid?");
      if (result) {
        var updatedItem = await this.strapi.updateEntry(
          "invoices",
          parseInt(invoiceItem.id),
          {
            paid: true,
          }
        );
        Object.assign(invoiceItem, updatedItem);
      }
    },
    async sendInvoice(item, invoiceItem) {
      this.strapi.request("POST", "/sendInvoice", {
        data: {
          type: "customer",
          customer: item.id,
          invoiceItem: invoiceItem.id,
        },
      });
      alert("Invoice sent.");
    },
    async shareInvoice(item, invoiceItem) {
      // var result = confirm("Are you sure you want to mark invoice as paid?");
      // if (result) {
      var updatedItem = await this.strapi.updateEntry(
        "invoices",
        parseInt(invoiceItem.id),
        {
          shared: true,
        }
      );
      Object.assign(invoiceItem, updatedItem);
      // }
    },
    async downloadInvoice(item, invoiceItem) {
      var invoiceOwner = invoiceItem.customer
        ? invoiceItem.customer.name
        : `${invoiceItem.user.firstName} ${invoiceItem.user.lastName}`;

      let pdfName = `${invoiceOwner}. Invoice #${invoiceItem.id}.`;
      var imgData = require("@/assets/base64/logo.js");
      // console.log(imgData);
      var doc = new jsPDF();
      doc.setFontSize(12);
      doc.setTextColor(160, 160, 160);
      doc.text(`Created: ${invoiceItem.dateCreated}`, 10, 10);
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(14);
      doc.text(`${invoiceOwner}.`, 10, 20);
      doc.text(`Invoice #${invoiceItem.id}`, 10, 28);
      doc.setFontSize(12);
      doc.setTextColor(160, 160, 160);
      doc.text(`(${invoiceItem.dateStart} - ${invoiceItem.dateEnd})`, 10, 35);
      doc.setTextColor(0, 0, 0);
      doc.addImage(imgData.default, "JPEG", 160, 5, 40, 19.6); //0.4

      doc.autoTable({
        // styles: { fillColor: [255, 255, 255] },
        // tableLineWidth: 1,
        // columnStyles: { 1: { halign: "center" }, 2: { halign: "center" }  }, //, fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 40 },
        head: [
          ["Date", "Appointment time", "Destination", "Passenger", "Cost"],
        ],
        body: invoiceItem.records.map((item) => {
          return [
            item.date,
            item.time,
            item.destination,
            item.patientName,
            `$${item.price}`,
          ];
        }),
      });

      doc.setFontSize(11);
      doc.text(
        `Total: $${invoiceItem.cost}`,
        168,
        50 + 10 * invoiceItem.records.length
      );

      doc.setTextColor(160, 160, 160);
      doc.setFontSize(11);
      doc.text(
        invoiceItem.customer
          ? `THANK YOU FOR ALLOWING US TO PARTICIPATE IN YOUR PATIENTS CARE`
          : "THANK YOU",
        30,
        50 + 10 * (invoiceItem.records.length + 5)
      );
      doc.setTextColor(0, 0, 0);

      //pages
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width; //Optional
      const pageHeight = doc.internal.pageSize.height; //Optional
      doc.setFontSize(10); //Optional
      doc.setTextColor(0, 0, 0);

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2; //Can be fixed number
        let verticalPos = pageHeight - 5; //Can be fixed number
        doc.setPage(j);
        doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
          align: "center", //Optional text styling});
        });
      }

      doc.save(pdfName + ".pdf");
    },
    async deleteInvoice(item, invoiceItem) {
      const index = item.invoices.findIndex((i) => invoiceItem.id == i.id);
      var result = confirm("Are you sure you want to delete this item?");
      if (result) {
        await this.strapi.deleteEntry("invoices", invoiceItem.id);
        item.invoices.splice(index, 1);
      }
    },

    async deleteItem(item, data) {
      const index = data.customers.findIndex((i) => item.id == i.id);
      var result = confirm("Are you sure you want to delete this item?");
      if (result) {
        await this.strapi.deleteEntry("customers", item.id);
        data.customers.splice(index, 1);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // Object.assign(this.editedItem, this.defaultItem);
        this.editedItem = this.lodash.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(data) {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        if (this.editedIndex > -1) {
          var updatedItem = await this.strapi.updateEntry(
            "customers",
            parseInt(this.editedItem.id),
            this.editedItem
          );
          Object.assign(data.customers[this.editedIndex], updatedItem);
        } else {
          // this.editedItem.username = this.editedItem.email;
          var newItem = await this.strapi.createEntry(
            "customers",
            this.editedItem
          );
          data.customers.push(newItem);
        }
        this.close();
      }
    },
    //
    async createInvoice(item) {
      if (!this.invoiceDateStart || !this.invoiceDateEnd) {
        return;
      }

      var startDate = this.timeStringToDateTime(this.invoiceDateStart);
      var endDate = this.timeStringToDateTime(this.invoiceDateEnd);

      var costs = 0;
      var records = [];

      var subOrders = await this.strapi.getEntries("sub-orders", {
        customer: item.id,
      });

      for (let i = 0; i < subOrders.length; i++) {
        if (subOrders[i].status == "Completed") {
          var subOrderDate = this.timeStringToDateTime(subOrders[i].date);

          if (
            subOrderDate.diff(startDate, "days").toObject().days >= 0 &&
            subOrderDate.diff(endDate, "days").toObject().days <= 0
          ) {
            costs += subOrders[i].price;
            // console.log(subOrders[i]);
            records.push({
              date: subOrders[i].date,
              time: subOrders[i].time,
              price: subOrders[i].price,
              patientName: subOrders[i].patientName,
              destination: subOrders[i].destination,
            });
          }
        }
      }

      var today = DateTime.local();
      today = DateTime.local(today.year, today.month, today.day);
      // console.log(today.toISODate());

      var invoice = await this.strapi.createEntry("invoices", {
        dateStart: this.invoiceDateStart,
        dateEnd: this.invoiceDateEnd,
        dateCreated: today.toISODate(),
        paid: false,
        cost: costs,
        customer: item.id,
        user: undefined, //!!!
        records: records,
      });

      if (!item.invoices) item.invoices = [];
      item.invoices.push(invoice);
    },
  },
};
</script>
<style lang="scss" scoped></style>
